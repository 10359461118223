import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import Markdown from 'markdown-to-jsx';
import parse from "html-react-parser";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import PressPostCard from "../../components/cards/PressPostCard";

import Col from "../../components/styled/grid/Col";
import {Body, Header2, Title} from "../../components/styled/typography/Typography";
import {BlogHeroImg, BlogTemplateImg} from "../../components/styled/pages/about/blog/BlogComponents";

const BlogTemplate = ({data}) => {

    const {content, title, author, date, short_description, SEO, hero} = data.blog;
    const {nodes} = data.related;
    const [actualLang, setActualLang] = useState("hu");

    useEffect(() => {
        if (window.location.host.includes(".hu") && document.documentElement.lang === "hu") {
            setActualLang("hu")
        } else if(window.location.host.includes(".hu") && document.documentElement.lang === "en"){
            setActualLang("en")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === "hu"){
            setActualLang("hu")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === "en"){
            setActualLang("en")
        } else if(window.location.host.includes(".hu") && document.documentElement.lang === ""){
            setActualLang("hu")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === ""){
            setActualLang("en")
        }
    }, []);

    return (
        <Layout lang={actualLang} seo={SEO}>
            <BlogHeroImg imgsrc={hero.url}>
                <SectionLayoutGrid customMinHeight="large" customBackground="none">
                    <Col>
                        <Title color="white" marginBottom="medium">
                            {title}
                        </Title>
                        <Body color="white" small>
                            {"Written by: " + author}
                        </Body>
                        <Body color="white" small>
                            {"Created: " + date}
                        </Body>
                        <Body color="white" marginTop="small" bold>
                            {short_description}
                        </Body>
                    </Col>
                </SectionLayoutGrid>
            </BlogHeroImg>
            <SectionLayoutGrid customBackground="middle">
                <Col className="markdown">
                    <Markdown children={content}/>
                </Col>
                <Col grid>
                    <BlogTemplateImg alt="license" src="/assets/blog-licenc.svg"/>
                    <Body color="body-text" small textAlign="center">
                        {actualLang === "hu" ? parse("Ez a blogposzt a <a href='https://creativecommons.org/licenses/by-sa/4.0/' rel='noreferrer' target='_blank'>Creative Commons Attribution-ShareAlike 4.0 International (CC-BY-SA 4.0) License</a> feltételei mellett licencelődik.") : parse("This blogpost is licensed under a <a href='https://creativecommons.org/licenses/by-sa/4.0/' rel='noreferrer' target='_blank'>Creative Commons Attribution-ShareAlike 4.0 International (CC-BY-SA 4.0) License</a>.")}
                    </Body>
                </Col>
            </SectionLayoutGrid>
            <SectionLayoutGrid customBackground="middle">
                <Col>
                    <Header2 color="white" textAlign="center" marginBottom="medium">
                        {actualLang === "hu" ? "Kapcsolódó tartalmak" : "Related Posts"}
                    </Header2>
                </Col>
                {nodes.map((item, index) => (
                    <Col span={4} grid key={index}>
                        <PressPostCard
                            avatar={item.avatar.url}
                            date={item.date}
                            title={item.title}
                            short_description={item.short_description}
                            slug={"/blogs/" + item.slug}
                            locale={actualLang}
                        />
                    </Col>
                ))}
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleBlog($slug: String) {
         blog: strapiBlogposts(slug: { eq: $slug }) {
           content
           title
           short_description
           author
           date(formatString: "YYYY-MM-DD")
           title
           hero {
              url
           }
           SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
         }
         related: allStrapiBlogposts(
            sort: {fields: date, order: DESC}
            limit: 3
            filter: {slug: {ne: $slug}}
           ) {
            nodes {
              author
              avatar {
                url
              }
              content
              date(formatString: "YYYY-MM-DD")
              id
              short_description
              slug
              title
            }
           }
   }
 `

export default BlogTemplate
