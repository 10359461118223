import styled from "styled-components";

export const BlogTemplateImg = styled.img`
  place-self: center;
  width: 75px;
  margin-bottom: 10px;
`

export const BlogHeroImg = styled.div`
  background-image: ${props => props.imgsrc && "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + props.imgsrc + ")"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`